import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

const TypingText = forwardRef(function RenderText({
  text,
  delay,
  infinite,
  onCompleted,
  onContinueStatusChange
}, ref) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bubbleClearedIndex, setBubbleClearedIndex] = useState(0);

  const continueRenderingLetters = () => {
    renderLetterText();
  }

  useImperativeHandle(ref, () => ({
    continueRenderingLetters,
  }));

  function findPreviousSentenceEnd(fullText, currentLetterIndex) {
    let closestDotIndex = null;

    for (let i = 0; i < Math.min(currentLetterIndex, fullText.length); i++) {
      if (fullText[i] === ".") {
        closestDotIndex = i;
      }
    }

    return closestDotIndex;
  }

  const renderLetterText = () => {
    setCurrentText((prevText) => prevText + text[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  useEffect(() => {
    let timeout;

    (async () => {
      if (
        (!infinite && currentIndex < text.length) ||
        (infinite && currentIndex <= text.length)
      ) { // eslint-disable-next-line
        if (currentIndex % 250 !== 249 || currentIndex == 0) {
          timeout = setTimeout(() => {
            renderLetterText();
          }, delay);
        }else {
          onContinueStatusChange();
        }

        //If the text is too long, clear the bubble and continue from the end of last sentence
        if (currentIndex % 250 === 0) {
          const lastDotIndex = findPreviousSentenceEnd(
            currentText,
            bubbleClearedIndex + 250
          );
          const newCurrentText = currentText.slice(lastDotIndex + 1);
          setCurrentText(newCurrentText);
          setBubbleClearedIndex(bubbleClearedIndex + 250);
        }
      } else if (infinite) {
        setCurrentIndex(0);
        setCurrentText("");
      }

      if (onCompleted && currentIndex + 1 === text.length) {
        onCompleted();
      }
    })();

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [currentIndex, delay, infinite, text]);

  return <span>{currentText}</span>;
});

export default TypingText;