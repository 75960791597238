import React, { useState } from "react";
import SearchIcon from "../../../sources/icons/SearchIcon.svg";
import MessageBubble from "../../../sources/icons/MessageBubble.svg";
import { useEffect } from "react";
import QuickConversationButton from "./QuickConversationButton";

const menu = [
  { title: "All" },
  { title: "Incomplete" },
  { title: "Completed" },
];

function ConversationsBar({ conversations, openModal, currentConversationDetails }) {
  const [activeConversationIndex, setActiveConversationIndex] = useState(0);
  const [activeConversationCategoryIndex, setActiveConversationCategoryIndex] =
    useState(0);

  const openConversation = (conversation) => {
    window.open("/?conversationId=" + conversation.conversationId, "_self");
  };

  const updateCurrentConversationIndexAfterReload = () => {
    const result = conversations.findIndex((v, index) => {
      if (v.conversationTitle === currentConversationDetails.conversationTitle) {
        return true
      } else {
        return false;
      }
    })

    if (result > -1) {
      setActiveConversationIndex(result);
    }
  }


  useEffect(() => {
    updateCurrentConversationIndexAfterReload();
    // eslint-disable-next-line
  }, [conversations, currentConversationDetails])


  return (
    <div className="bg-[#F9FAFC] w-full h-screen flex flex-col py-6 shadow-md">
      <div className="w-full justify-between flex items-center px-6">
        <h1 className="font-semibold text-xl">Kanka Bot</h1>
      </div>
      <div className="w-full mt-4 justify-between flex items-center px-6">
        <QuickConversationButton openModal={openModal} />
        <img src={SearchIcon} alt="Search Icon" height={20} width={20} />
      </div>
      <ul className="no-underline flex flex-row text-md justify-between cursor-pointer pt-4 pb-4 px-6">
        {menu.map((v, i) => {
          return (
            <button
              onClick={() => {
                setActiveConversationCategoryIndex(i);
              }}
              key={i}
            >
              <li
                className={`w-auto ${
                  i === activeConversationCategoryIndex &&
                  "border-b-2 border-[#6B7DBB]"
                }`}
              >
                {v.title}
              </li>
            </button>
          );
        })}
      </ul>
      <div className="h-max overflow-y-scroll">
        {conversations.map((conversation, i) => {
          return (
            <div
              className={`flex flex-col w-full justify-center items-center pt-4 mb-4 px-6 cursor-pointer ${
                activeConversationIndex === i && "bg-white shadow-md"
              }`}
              onClick={() => {
                setActiveConversationIndex(i);
                openConversation(conversation);
              }}
              key={i}
            >
              <div className="flex w-full items-start flex-row justify-between ">
                <div className="w-1/6">
                  <img
                    src={MessageBubble}
                    alt="Search Icon"
                    height={20}
                    width={20}
                  />
                </div>
                <div className="w-5/6 flex flex-col">
                  <div className={`text-sm font-semibold`}>
                    {conversation.conversationTitle}
                  </div>
                  <div className="text-xs mt-1">
                    {conversation.conversationDescription}
                  </div>
                </div>
              </div>
              <div
                className={`${
                  activeConversationIndex === i
                    ? "bg-transparent"
                    : "bg-gray-200"
                } w-5/6 mt-4  h-0.5 rounded-xl`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ConversationsBar;
