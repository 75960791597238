import axios from 'axios';
const { baseUrl } = require("./constants");

export const fetchUserInfo = (userToken) => {
    return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/user/user-info", {
            headers: {
              "Content-Type": "application/json",
              authorization: userToken,
            },
          })
          .then(({ data }) => {
            return resolve(data)
          }).catch((err) => {
            return reject(err);
          });
    })
}