import CustomButton from "../../components/CustomButton";
import SocialSkills from "../../sources/images/Social_Skills.png";
import html2canvas from "html2canvas";
import React, { useState, useEffect } from "react";
import { store } from "../../reducers/store";
import { saveToolForm } from "../../utils/saveToolForm";

const SocialSkillForm = ({
  setShowSocialSkillForm,
  socialSkillFormData,
  closeModal,
}) => {
  const [downloadedImage, setDownloadedImage] = useState(null);
  const authReducer = store.getState().authReducer;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();

  useEffect(() => {
    
    generatePNG();
    
    // eslint-disable-next-line
  }, []);

  const adjustFontSize = (text, elementId) => {
    const wordCount = text.trim().length;
    const element = document.getElementById(elementId);
    if (element) {
      if (wordCount > 170) {
        element.style.fontSize = "13px";
      } else {
        element.style.fontSize = "15px";
      }
    }
  };

  const generatePNG = async () => {
    try {
      const container = document.createElement("div");
      container.style.visibility = "hidden";
      container.style.position = "absolute";
      container.style.width = "1024px";
      container.style.height = "768px";
      document.body.appendChild(container);

      container.innerHTML = ` <div id="container" className="relative w-96 h-96" >
      <img src="${SocialSkills}" alt="Cost Benefit" class="w-full h-1/2 object-contain" />
      <div class="absolute inset-0">
      <p class="absolute mt-44 font-bold ml-48 ">  ${authReducer?.firstName} ${
        authReducer?.lastName
      } </p>
      <p class="absolute left-[26rem] font-bold mt-44 ml-96 text-center">   ${formattedDate}</p>
      <span class= "absolute left-[35.5rem] font-bold mt-[18.5rem]  w-1/3 overflow-y-hidden h-8  text-start">${
        socialSkillFormData.socialSkill
      } </span>
      ${
        socialSkillFormData.step1
          ? ` <p id="step1"  class= "absolute left-[11.5rem] font-bold top-24 mt-[18.5rem] bg-red-0 w-3/4 overflow-y-hidden h-8 text-start"> ${socialSkillFormData.step1} </p>`
          : ""
      }
      ${
        socialSkillFormData.step2
          ? `<p id="step2" class= "absolute left-[11.5rem] font-bold top-36 mt-[18.2rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step2}</p>`
          : ""
      }
      ${
        socialSkillFormData.step3
          ? `<p id="step3" class= "absolute left-[11.5rem] font-bold top-48 mt-[18.2rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step3} </p>`
          : ""
      }
      ${
        socialSkillFormData.step4
          ? `<p id="step4" class= "absolute left-[11.5rem] font-bold top-60 mt-[18.2rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step4} </p>`
          : ""
      }
      ${
        socialSkillFormData.step5
          ? `<p id="step5" class= "absolute left-[11.5rem] font-bold top-72 mt-[18.2rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start">${socialSkillFormData.step5} </p>`
          : ""
      }
      ${
        socialSkillFormData.step6
          ? `<p id="step6" class= "absolute left-[11.5rem] font-bold top-80 mt-[19rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step6} </p>`
          : ""
      }
      ${
        socialSkillFormData.anticipate
          ? `<p id="anticipate" class= "absolute left-[7.5rem] font-bold top-96 mt-[16rem] bg-red- w-4/5 overflow-hidden h-40  leading-[5.5rem] text-start indent-[28rem]">${socialSkillFormData.anticipate} </p>`
          : ""
      }
      ${
        socialSkillFormData.situation
          ? `<p id="situation" class= "absolute left-[7.5rem] font-bold top-96 mt-[29.2rem] bg-red-00 w-4/5 overflow-hidden h-40  leading-[5.5rem] text-start indent-[27rem]">${socialSkillFormData.situation} </p>`
          : ""
      }
      ${
        socialSkillFormData.situation
          ? `<p id="skillagain" class= "absolute left-[7.5rem] font-bold top-96 mt-[45.5rem] bg-red-00 w-4/5 overflow-hidden h-24  leading-[2.7rem] text-start indent-[35rem]"> ${socialSkillFormData.skillagain} </p>`
          : ""
      } 
      ${
        socialSkillFormData.NeedImprovement
          ? '<p class="absolute left-[33rem] font-bold text-lg top-96 mt-[42.5rem] ml-96 text-center">X</p>'
          : ""
      }
      ${
        socialSkillFormData.Ok
          ? '<p class="absolute left-[14.2rem] font-bold text-lg top-96 mt-[42.5rem] ml-96 text-center">X</p>'
          : ""
      }
      ${
        socialSkillFormData.Good
          ? '<p class="absolute left-[3rem] font-bold text-lg top-96 mt-[43rem] ml-96 text-center">X</p>'
          : ""
      }
      ${
        socialSkillFormData.Excellent
          ? '<p class="absolute left-0 font-bold text-lg top-96 mt-[42.5rem] ml-56 text-center">X</p>'
          : ""
      }
      </div>
      </div>`;

      if (socialSkillFormData.anticipate) {
        adjustFontSize(socialSkillFormData.anticipate, "anticipate");
      }
      if (socialSkillFormData.situation) {
        adjustFontSize(socialSkillFormData.situation, "situation");
      }
      if (socialSkillFormData.skillagain) {
        adjustFontSize(socialSkillFormData.skillagain, "skillagain");
      }

      container.style.visibility = "visible";

      const canvas = await html2canvas(container, {
        width: 1024,
        height: 1300,
      });

      const imageData = canvas.toDataURL("image/png");

      setDownloadedImage(imageData);
      document.body.removeChild(container);
      console.log(socialSkillFormData)

      if (
        socialSkillFormData.Ok ||
        socialSkillFormData.NeedImprovement ||
        socialSkillFormData.Good ||
        socialSkillFormData.Excellent
      ) {
        // Convert base64 to Blob
        const blob = await fetch(imageData).then((res) => res.blob());
        saveToolForm(blob, "Social-skills");
      }
    } catch (error) {
      console.error("Error generating PNG:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadedImage;
    link.download = "SocialSkill-Form.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="absolute z-50 w-5/6 bg-transparent xl:w-[80vh]  lg:w-1/2 h-[80vh] bg-white rounded-lg  ">
        <div className="relative xsm:space-x-2 mb-4 mt-2 z-50 flex justify-center space-x-4 print:hidden ">
          <CustomButton onClick={handlePrint} buttonColor="primary">
            Print
          </CustomButton>
          <CustomButton onClick={handleDownload} buttonColor="primary">
            Download
          </CustomButton>
          <CustomButton
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
            onClick={() => {
              setShowSocialSkillForm(false);
              setTimeout(() => {
                window.botpressWebChat.sendEvent({
                  type: "proactive-trigger",
                  channel: "web",
                  payload: { text: "SocialSkill Form displayed!" },
                });
              }, 500);
              closeModal();
            }}
            customStyle={"mr-2"}
          >
            Close
          </CustomButton>
        </div>
        <img
          src={downloadedImage}
          alt="SocialSkill"
          className="w-full h-full "
        />
      </div>
    </div>
  );
};

export default SocialSkillForm;
