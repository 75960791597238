import CustomButton from "../../components/CustomButton";
import ProblemSolvingImg from "../../sources/images/ProblemSolving.png";
import html2canvas from "html2canvas";
import React, { useState, useEffect } from "react";
import { store } from "../../reducers/store";
import { saveToolForm } from "../../utils/saveToolForm";

const ProblemSolving = ({
  setShowProblemSolvingForm,
  problemSolvingFormData,
  closeModal,
}) => {
  const [downloadedImage, setDownloadedImage] = useState(null);
  const authReducer = store.getState().authReducer;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();

  useEffect(() => {
    generatePNG();
  });

  const generatePNG = async () => {
    try {
      const container = document.createElement("div");
      container.style.visibility = "hidden";
      container.style.position = "absolute";
      container.style.width = "1024px";
      container.style.height = "768px";
      document.body.appendChild(container);

      container.innerHTML = `
        <div id="container" className="relative w-96 h-96" >
          <img src="${ProblemSolvingImg}" alt="Problem Solving" class="w-full h-1/2 object-contain" />
          <div class="absolute inset-0">
            <p class="absolute mt-44 pt-2 font-bold ml-48 ">${
              authReducer?.firstName
            } ${authReducer?.lastName}</p>
            <p class="absolute left-[26rem] font-bold mt-44 pt-2 ml-96 text-center">${formattedDate}</p>
            ${
              problemSolvingFormData.problem
                ? `<p class="absolute mt-32 top-36 font-bold ml-60"> ${problemSolvingFormData.problem}</p>`
                : ""
            }
            ${
              problemSolvingFormData.goals1 && problemSolvingFormData.goals2
                ? `<p class="absolute mt-32 top-48 font-bold ml-60">1. ${problemSolvingFormData.goals1} 2. ${problemSolvingFormData.goals2} </p>`
                : " "
            }
            ${
              problemSolvingFormData.options1
                ? `<p class="absolute mt-[23rem] w-80 top-56 font-bold ml-36">${problemSolvingFormData.options1} </p>
            <p class="absolute mt-[25.5rem] top-56 font-bold w-80  ml-36">${problemSolvingFormData.options2} </p>
            <p class="absolute mt-[28.5rem] top-56 font-bold h-14  overflow-hidden w-80 ml-36">${problemSolvingFormData.options3}</p>
            <p class="absolute mt-[31.5rem] top-56 font-bold h-14  overflow-hidden w-80 ml-36">${problemSolvingFormData.options4}  </p>
            <p class="absolute mt-[34.7rem] top-56 font-bold h-14  overflow-hidden w-80 ml-36">${problemSolvingFormData.options5} </p>`
                : ""
            }
            ${
              problemSolvingFormData.positive1
                ? `<p class="absolute mt-[23rem] top-56 font-bold h-14 overflow-hidden w-40 ml-[30rem]"> ${problemSolvingFormData.positive1}</p>
            <p class="absolute mt-[25.5rem] top-56 font-bold h-14 overflow-hidden w-40 ml-[30rem]"> ${problemSolvingFormData.positive2} </p>
            <p class="absolute mt-[28.5rem] top-56 font-bold h-14 overflow-hidden w-40 ml-[30rem]"> ${problemSolvingFormData.positive3} </p>
            <p class="absolute mt-[31.5rem] top-56 font-bold h-14 overflow-hidden w-40 ml-[30rem]"> ${problemSolvingFormData.positive4} </p>
            <p class="absolute mt-[34.5rem] top-56 font-bold h-14 overflow-hidden w-40 ml-[30rem]"> ${problemSolvingFormData.positive5} </p>`
                : ""
            }
            ${
              problemSolvingFormData.negative1
                ? `<p class="absolute mt-[23rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[39.5rem]">${problemSolvingFormData.negative1}</p>
            <p class="absolute mt-[25.5rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[39.5rem]">${problemSolvingFormData.negative2} </p>
            <p class="absolute mt-[28.5rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[39.5rem]">${problemSolvingFormData.negative3} </p>
            <p class="absolute mt-[31.5rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[39.5rem]">${problemSolvingFormData.negative4} </p>
            <p class="absolute mt-[34.5rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[39.5rem]">${problemSolvingFormData.negative5} </p>`
                : ""
            }
            ${
              problemSolvingFormData.Matches9 === "Yes"
                ? `
                <p class="absolute mt-[35.5rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[49.7rem]">X</p>`
                : ""
            }
              ${
                problemSolvingFormData.Matches10 === "Yes"
                  ? `
                <p class="absolute mt-[35.5rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[55.5rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches7 === "Yes"
                  ? `
                <p class="absolute mt-[33rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[49.7rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches8 === "Yes"
                  ? `
                <p class="absolute mt-[33rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[55.5rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches5 === "Yes"
                  ? `
                <p class="absolute mt-[30rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[49.7rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches6 === "Yes"
                  ? `
                <p class="absolute mt-[30rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[55.5rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches3 === "Yes"
                  ? `
                <p class="absolute mt-[27rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[49.7rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches4 === "Yes"
                  ? `
                <p class="absolute mt-[27rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[55.5rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches1 === "Yes"
                  ? `
                <p class="absolute mt-[24rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[49.7rem]">X</p>`
                  : ""
              }
              ${
                problemSolvingFormData.Matches2 === "Yes"
                  ? `
                <p class="absolute mt-[24rem] top-56 font-bold h-14 overflow-hidden w-36 ml-[55.5rem]">X</p>`
                  : ""
              }
            ${
              problemSolvingFormData.steps1
                ? `<p class="absolute mt-[35rem] top-96 font-bold h-8 overflow-hidden ml-36">${problemSolvingFormData.steps1}</p>
            <p class="absolute mt-[38rem] top-96 font-bold h-8 overflow-hidden ml-36">${problemSolvingFormData.steps2} </p>
            <p class="absolute mt-[41rem] top-96 font-bold h-8 overflow-hidden w-88 ml-36">${problemSolvingFormData.steps3}</p>`
                : ""
            }
            ${
              problemSolvingFormData.socialSkill
                ? `<p class="absolute mt-[44.5rem] top-96 font-bold h-8 overflow-hidden w-80 ml-96">${problemSolvingFormData.socialSkill}</p>`
                : ""
            }
            ${
              problemSolvingFormData.carryOut
                ? `
            <p class="absolute mt-[48rem] top-96 font-bold h-8 overflow-hidden w-80 ml-[25rem]">${problemSolvingFormData.carryOut} with ${problemSolvingFormData.carryWith} at ${problemSolvingFormData.carryAt}</p>`
                : ""
            }
          </div>
        </div>`;

      container.style.visibility = "visible";

      const canvas = await html2canvas(container, {
        width: 1024,
        height: 1300,
      });

      const imageData = canvas.toDataURL("image/png");

      setDownloadedImage(imageData);
      document.body.removeChild(container);

      if (problemSolvingFormData?.carryAt) {
        // Convert base64 to Blob
        const blob = await fetch(imageData).then((res) => res.blob());
        saveToolForm(blob, "Problem-solving");
      }
    } catch (error) {
      console.error("Error generating PNG:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadedImage;
    link.download = "ProblemSolving-Form.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="absolute z-50 w-5/6 bg-transparent xl:w-[80vh] lg:w-1/2 h-[80vh] bg-white rounded-lg">
        <div className="relative xsm:space-x-2 mb-4 mt-2 z-50 flex justify-center  space-x-4 print:hidden">
          <CustomButton onClick={handlePrint} buttonColor="primary">
            Print
          </CustomButton>
          <CustomButton onClick={handleDownload} buttonColor="primary">
            Download
          </CustomButton>
          <CustomButton
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
            onClick={() => {
              setShowProblemSolvingForm(false);
              setTimeout(() => {
                window.botpressWebChat.sendEvent({
                  type: "proactive-trigger",
                  channel: "web",
                  payload: { text: "Problem Solving Form displayed!" },
                });
              }, 500);
              closeModal();
            }}
            customStyle={"mr-2"}
          >
            Close
          </CustomButton>
        </div>
        <img
          src={downloadedImage}
          alt="ProblemSolving"
          className="w-full h-full rounded-lg "
        />
      </div>
    </div>
  );
};

export default ProblemSolving;
