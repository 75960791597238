import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { store } from "../../reducers/store";
import ConversationsBar from "./components/ConversationsBar";
import ConversationBox from "./components/ConversationBox";
import { baseUrl, botpressUrl } from "../../utils/constants";
import NewConversationModal from "./components/NewConversationModal";
import axios from "axios";
import "./HomePage.css";
import FlowProvider from "../../providers/FlowProvider";
import { sanitizeAndParseJson } from "../../utils/sanitizeAndParseJson";
import { setCurrentConversation as setCurrentConversationToReducer } from "../../reducers/app.reducer";

function HomePage() {
  const flowProviderRef = useRef()
  const [isInitialized, setInitializedState] = useState(false);
  const [isConversationLoaded, setConversationLoaded] = useState(false);
  const [currentConversationDetails, setCurrentConversationDetails] = useState({
    conversationTitle: "",
    conversationDescription: "",
    conversationId: "",
  });
  const [conversations, setConversations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const conversationConvId = searchParams.get("conversationId");
  const authReducer = store.getState().authReducer;
  const userId = authReducer.userId;
  const userToken = localStorage.getItem("user-token");
  const [avatarText, setAvatarText] = useState("");
  const [showAvatar, setShowAvatar] = useState(false);
  const [showCostBenefitForm, setShowCostBenefitForm] = useState(false);
  const [costBenefitFormData, setCostBenefitFormData] = useState(null);
  const [showSocialSkillForm, setShowSocialSkillForm] = useState(false);
  const [socialSkillFormData, setShowSocialSkillFormData] = useState(null);
  const [showBehaviorChainForm, setShowBehaviorChainForm] = useState(null);
  const [behaviorChainFormText, setBehaviorChainFormText] = useState("");
  const [showProblemSolvingForm, setShowProblemSolvingForm] = useState(false);
  const [problemSolvingFormData, setProblemSolvingFormData] = useState(null)

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  // Function to fetch conversations
  const fetchConversations = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const userToken = localStorage.getItem("user-token");
        const response = await axios.get(
          baseUrl + "/api/conversation/list-conversations",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: JSON.parse(userToken),
            },
          }
        );
        if (response.data.data.length > 0) {
          setConversations(response.data.data.reverse());
          if (conversationConvId) {
            response.data.data.map((conversation) => {
              if (conversation.conversationId === conversationConvId) {
                store.dispatch(setCurrentConversationToReducer(conversation));
                return setCurrentConversationDetails(conversation);
              }
              return "";
            });
          } else {
            store.dispatch(setCurrentConversationToReducer(response.data.data[0]));
            setCurrentConversationDetails(response.data.data[0]);
          }
          setConversationLoaded(true);

          resolve(response.data.data[0]);
        } else {
          resolve();
        }
      } catch (error) {
        console.error("Error fetching conversations:", error);
        reject(error);
      }
    });
  };

  const onCreateConversation = async () => {
    const conversation = await fetchConversations();

    if (isInitialized) {
      openConversation(conversation, "&newConversation=true");
    }
  };

  const openConversation = (conversation, additionalQueryString = null) => {
    let url = "/?conversationId=" + conversation.conversationId;

    url = additionalQueryString ? url + additionalQueryString : url;

    window.open(url, "_self");
  };

  useEffect(() => {
    fetchConversations();
    if (!isInitialized && conversations.length > 0) {
      if (userId && userToken) {
        setInitializedState(true);
        if (conversationConvId) {
          initializeChatBot(userId, JSON.parse(userToken), {
            conversationId: conversationConvId,
          });
        } else {
          initializeChatBot(userId, JSON.parse(userToken), {
            conversationId: conversations[0].conversationId,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [isInitialized, userId, userToken, isConversationLoaded]);

  const loadSpecificFlow = (flowId) => {
    setTimeout(() => {
      window.botpressWebChat.sendEvent({
        type: "jumpTo",
        channel: "web",
        payload: {
          flowId: flowId,
        },
      });
    }, 1000);
  };

  // If the conversation is new or if the conversation session is timed out
  // Greeting message displays
  // This condition is handled in botpress before_incoming_middleware
  const greetUser = () => {
    const greetings = [
      "Hello " + authReducer.firstName,
      "Hi " + authReducer.firstName,
      "Hey there, " + authReducer.firstName,
      "Greetings, " + authReducer.firstName,
      "Good to see you, " + authReducer.firstName,
      "Hey " + authReducer.firstName + "!",
      "Hiya, " + authReducer.firstName,
      "Hey, " + authReducer.firstName + "!",
      "Hey " + authReducer.firstName + ", how's it going?",
    ];

    const randomGreeting =
      greetings[Math.floor(Math.random() * greetings.length)];

    setTimeout(() => {
      window.botpressWebChat.sendEvent({
        type: "proactive-trigger",
        channel: "web",
        payload: { text: randomGreeting },
      });
    }, 500);
  };

  const initializeChatBot = async (userId, userToken, chatOptions = {}) => {
    try {
      const rootLink = window.location.origin;

      //  maxMessagesHistory: 500,
      window.botpressWebChat.init(
        {
          host: botpressUrl,
          userId: userId,
          botId: "odrc",
          hideWidget: true,
          showPoweredBy: false,
          externalAuthToken: userToken,
          extraStylesheet: rootLink + "/styles/chatbot.css",
          botName: "Kanka Bot",
          enableTranscriptDownload: false,
          showConversationsButton: false,
          viewMode: "Fullscreen",
          ...chatOptions,
        },
        "#conversation-chat-container"
      );

      window.addEventListener("message", function (event) {
        //Identifies when the bot bubble is clicked and the sends 'proactive-trigger' event
        if (event.data.name === "configChanged") {
          setTimeout(function () {
            //window.botpressWebChat.configure({ externalAuthToken: 'my.jwt.token' })
            window.botpressWebChat.sendEvent({
              type: "setUserToken",
              channel: "web",
              payload: { userToken: userToken },
            });
          }, 3000);
        }

        if (event.data.name === "webchatLoaded") {
          const followUpParam = searchParams.get("followUp");
          const flowParam = searchParams.get("flow");
          if (followUpParam) {
            loadSpecificFlow(followUpParam);
          }
          if (flowParam) {
            loadSpecificFlow(flowParam);
          }
        }

        if (event.data.name === "webchatReady") {
          const isNewConversation = searchParams.get("newConversation");
          if (isNewConversation) {
            //Send first message to user as a chatbot
            greetUser();

            const params = new URLSearchParams(window.location.search);
            params.delete("newConversation");
            const newUrl = window.location.pathname + "?" + params.toString();
            window.history.replaceState({}, "", newUrl);
          }
        }

        if (event.data.actionName === "displayAvatar") {
          setAvatarText(event.data.data);
          setShowAvatar(true);
        }

        if (event.data.actionName === "videoRecording") {
          const escapedString = sanitizeAndParseJson(event.data.data);

          flowProviderRef.current?.videoRecorder.showVideoRecorder({
            title: escapedString.title,
            description: escapedString.description,
            currentConversationId: currentConversationDetails?.conversationId,
          });
        }

        if (event.data.actionName === "displayLastVideoRecording") {
          flowProviderRef.current?.displayVideo.showVideoDisplayer({
            currentConversationId: currentConversationDetails?.conversationId,
          });
        }

        if (event.data.actionName === "displayCostBenefitForm") {
          const escapedString = sanitizeAndParseJson(event.data.data);
          const data = escapedString;
          setCostBenefitFormData(data);
          setShowCostBenefitForm(true);
        }

        if(event.data.actionName === "displaySocialSkillForm"){
          const escapedString = sanitizeAndParseJson(event.data.data);
          const data = escapedString;
          setShowSocialSkillFormData(data);
          setShowSocialSkillForm(true);
        }

        if (event.data.actionName === "loadConversation") {
          const conversationId = event.data.data
          openConversation({ conversationId });
        }

        if (event.data.actionName === "displayProblemSolvingForm"){
          const data = JSON.parse(event.data.data);
          setProblemSolvingFormData(data);
          setShowProblemSolvingForm(true);
        }

        if (event.data.actionName === "displayBehaviorChainForm") {
          const escapedString = sanitizeAndParseJson(event.data.data);
          const data = escapedString;
          setBehaviorChainFormText(data);
          setShowBehaviorChainForm(true);
        }
      });
    } catch (error) {
      console.log("script error:", error);
    }
  };

  return (
    <div className="relative h-screen w-screen flex flex-row items-center justify-center ">
      <FlowProvider
      ref={flowProviderRef}
        avatar={{
          showAvatar,
          avatarText,
          setShowAvatarStatus: (status) => {
            setShowAvatar(status);
          },
        }}
        displayCostBenefitForm={{
          setShowCostBenefitForm,
          costBenefitFormData,
          showCostBenefitForm,
        }}
        displayBehaviorChainForm={{
          setShowBehaviorChainForm,
          showBehaviorChainForm,
          behaviorChainFormText,
        }}
        displayProblemSolvingForm={{
          setShowProblemSolvingForm,
          showProblemSolvingForm,
          problemSolvingFormData
        }}
        displaySocialSkillForm={{setShowSocialSkillForm, socialSkillFormData, showSocialSkillForm}}
      />
      <NewConversationModal
        isOpen={isOpen}
        openModal={openModal}
        closeModal={closeModal}
        onCreateConversation={onCreateConversation}
      />

      <div className="h-full w-0 hidden lg:flex lg:w-1/5">
        <ConversationsBar
          openModal={() => {
            openModal();
          }}
          currentConversationDetails={currentConversationDetails}
          setCurrentConversationDetails={(currentConversation) => {
            store.dispatch(setCurrentConversationToReducer(currentConversation));
            setCurrentConversationDetails(currentConversation);
          }}
          conversations={conversations}
        />
      </div>
      <div className="h-full w-full lg:w-4/5">
        <ConversationBox
          isConversationLoaded={isConversationLoaded}
          currentConversationDetails={currentConversationDetails}
          openModal={() => {
            openModal();
          }}
        />
      </div>
    </div>
  );
}

export default HomePage;
