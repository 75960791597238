import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../reducers/store";
import { setAuthData, setWelcomePageStatus } from "../../reducers/auth.reducer";
import { toast } from "react-toastify";

function LoginPage() {
  const [backendError, setBackendError] = useState(null);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (data) => {
    const loadingID = toast.loading("Please wait...");
    axios
      .post(baseUrl + "/api/auth/sign-in", {
        userName: data.userName,
        password: data.password,
      })
      .then(function ({ data }) {
        if (data.success) {
          localStorage.setItem(
            "user-token",
            JSON.stringify(data.data.authToken)
          );
          store.dispatch(setAuthData(data.data));
          toast.update(loadingID, {
            render: "Authenticated",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          if (data.data.offender.forwardWelcomingPage) {
            navigate(
              "/welcome?conversationId=" +
                data.data.offender.welcomeConversationId
            );
            store.dispatch(
              setWelcomePageStatus(!data.data.offender.forwardWelcomingPage)
            );
          } else {
            store.dispatch(setWelcomePageStatus(true));
            navigate("/");
          }
        } else {
          toast.update(loadingID, {
            render: data.message,
            type: "error",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          setBackendError(data.message);
        }
      })
      .catch(function (error) {
        toast.update(loadingID, {
          render: "An error occured.",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          closeOnClick: true,
        });

        console.log(error);
      });
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      const userToken = localStorage.getItem("user-token");
      if (userToken) {
        navigate("/");
      }
    }
  });

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <div className="bg-authBackground w-full h-full absolute blur-xl" />
      <form
        className="z-20 2xl:w-1/5 md:w-2/5 w-4/5 bg-white py-10 flex flex-col items-center gap-3 rounded-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="md:w-2/5 w-4/5 flex justify-center mb-5">
          <h1 className="text-3xl font-bold ">Sign In</h1>
        </div>
        {backendError && (
          <span role="alert" className="text-red-400 text-md font-bold -mt-3">
            {backendError}
          </span>
        )}
        <label className="flex flex-col w-8/12 text-sm">
          Username
          <input
            type="text"
            id="userName"
            autoComplete="off"
            {...register("userName", { required: true })}
            className="bg-gray-50 border-gray-500 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#6B7DBB] focus:border-[#6B7DBB] block w-full p-2.5 focus:outline-none"
            placeholder="Username"
            required
          />
        </label>
        <label className="flex flex-col w-8/12 text-sm mt-2">
          Password
          <input
            type="password"
            id="password"
            autoComplete="off"
            {...register("password", { required: true })}
            className="bg-gray-50 border-gray-500 border-2 text-gray-900 text-sm rounded-lg focus:ring-[#6B7DBB] focus:border-[#6B7DBB] block w-full p-2.5 focus:outline-none"
            placeholder="Password"
            required
          />
        </label>
        <input
          type="submit"
          value="Continue"
          className="bg-[#47A18A] hover:bg-blue-500 mt-5 text-white font-bold py-2 px-4 rounded"
        />
      </form>
    </div>
  );
}

export default LoginPage;
