import React, { useEffect, useRef, useState } from "react";
import helloAnimation from "../../sources/lotties/hello.json";
import Lottie from "react-lottie";
import CustomButton from "../../components/CustomButton";
import { baseUrl, botpressUrl } from "../../utils/constants";
import { useSelector } from "react-redux";
import { store } from "../../reducers/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setWelcomePageStatus } from "../../reducers/auth.reducer";
import "./WelcomePage.css";
import axios from "axios";

function WelcomePage() {
  const [displayNeverMeet, setDisplayNeverMeet] = useState(false);
  const [initializeChatbot, setInitializeChatbot] = useState(false);
  const itLooksLike = useRef(null);
  const weNever = useRef(null);
  const tellMeAboutYou = useRef(null);
  const letsGoButton = useRef(null);
  const chatRef = useRef(null);
  const { userId, userToken } = useSelector((state) => state.authReducer);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const initializeChat = () => {
    letsGoButton.current.style.opacity = "0";
    tellMeAboutYou.current.style.opacity = "0";
    const welcomeConvId = searchParams.get("conversationId");
    setTimeout(() => {
      setInitializeChatbot(true);
      letsGoButton.current.style.display = "none";
      tellMeAboutYou.current.style.display = "none";

      setTimeout(() => {
        chatRef.current.style.opacity = "1";

        try {
          const rootLink = window.location.origin;

          window.botpressWebChat.init(
            {
              host: botpressUrl,
              userId: userId,
              botId: "odrc",
              hideWidget: true,
              showPoweredBy: false,
              externalAuthToken: userToken,
              extraStylesheet: rootLink + "/styles/chatbot.css",
              botName: "Kanka Bot",
              enableTranscriptDownload: false,
              showConversationsButton: false,
              viewMode: "Fullscreen",
            },
            "#welcome-chat-container"
          );

          let forwardRequestCount = 0;
          window.addEventListener("message", async function (event) {
            if (event.data.name === "webchatLoaded") {
              window.botpressWebChat.sendEvent({
                type: "loadConversation",
                conversationId: welcomeConvId,
              });

              setTimeout(() => {
                window.botpressWebChat.sendEvent({
                  type: "jumpTo",
                  channel: "web",
                  payload: {
                    flowId: "user-welcome.flow.json",
                    nodeId: "Email-or-text-notification",
                  },
                });
              }, 3000);
            }
            if (event.data.name === "webchatReady") {
              setTimeout(function () {
                window.botpressWebChat.sendEvent({
                  type: "setUserToken",
                  channel: "web",
                  payload: { userToken: userToken },
                });
              }, 3000);
            }
            if (
              event.data.type === "data" &&
              event.data.actionName === "finalizeWelcomeConversation"
            ) {
              forwardRequestCount += 1;
              if (forwardRequestCount === 2) {
                finalizeWelcomeConversation(event.data.data);
              }
            }
          });
        } catch (error) {
          console.log("script error:", error);
        }
      }, 500);
    }, 1000);
  };

  useEffect(() => {
    const welcomingPageCompleted =
      store.getState().authReducer.welcomingPageCompleted;

    welcomingPageCompleted && navigate("/");
    // eslint-disable-next-line
  }, []);

  const updatOnboardingStatus = async (userToken) => {
    await axios.post(
      baseUrl + "/api/user/update-onboarding-status",
      {
        onboarded: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: userToken,
        },
      }
    );
  };

  /*
    Creates the first conversation for the user and redirects user to the homepage by opening the 
    conversation with it's related flow.
  */
  const finalizeWelcomeConversation = async (flowId) => {
    setTimeout(async () => {
      window.botpressWebChat.sendEvent({
        type: "finalizeWelcomeConversation",
        channel: "web",
      });

      store.dispatch(setWelcomePageStatus(true));

      //Create conversation to forward user
      let bpConversationId = null;
      try {
        let userToken = localStorage.getItem("user-token");
        userToken = JSON.parse(userToken);
        const response = await axios.post(
          baseUrl + "/api/conversation/create-conversation",
          {
            conversationTitle: "Hello Chatbot!",
            conversationDescription: "My First Conversation :)",
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: userToken,
            },
          }
        );

        updatOnboardingStatus(userToken);

        if (response.status === 201) {
          bpConversationId = response.data.data.bpConversationId;
        } else {
          console.error("Failed to create conversation");
        }
      } catch (error) {
        console.error("Error creating conversation:", error);
      }

      if (bpConversationId) {
        window.open(
          "/?conversationId=" + bpConversationId + "&flow=" + flowId + "&newConversation=true",
          "_self"
        );
      } else {
        console.log("Conversation id is null for forwarding user.");
      }
    }, 4000);
  };

  const startAnimations = () => {
    setTimeout(() => {
      itLooksLike.current.style.opacity = "1";
      setTimeout(() => {
        weNever.current.style.opacity = "1";
      }, 500);
      setTimeout(() => {
        weNever.current.style.opacity = "0";
        itLooksLike.current.style.opacity = "0";
        setTimeout(() => {
          weNever.current.style.display = "none";
          itLooksLike.current.style.display = "none";
          tellMeAboutYou.current.style.opacity = "1";
          setTimeout(() => {
            letsGoButton.current.style.opacity = "1";
          }, 1000);
        }, 1000);
      }, 2500);
    }, 200);
  };

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center relative">
      {!displayNeverMeet && !chatRef.current && (
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: helloAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          loop={false}
          width={350}
          height={350}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                setDisplayNeverMeet(true);
                startAnimations();
              },
            },
          ]}
        />
      )}
      {displayNeverMeet && !chatRef.current && (
        <>
          <div
            ref={itLooksLike}
            className="text-4xl font-bold text-primary transition-opacity duration-1000 ease-in-out opacity-0"
          >
            It looks like
          </div>
          <div
            ref={weNever}
            className="text-4xl font-bold text-primary transition-opacity duration-1000 ease-in-out opacity-0"
          >
            we've never met before!
          </div>
          <div
            ref={tellMeAboutYou}
            className="font-bold text-primary flex flex-col items-center justify-center transition-opacity duration-1000 ease-in-out opacity-0"
          >
            <div className="text-4xl">Can you tell me a little</div>
            <div className="text-5xl">about yourself?</div>
          </div>
          <div
            ref={letsGoButton}
            className="w-1/2 flex items-center justify-center transition-opacity duration-1000 ease-in-out opacity-0 mt-10"
          >
            <CustomButton
              buttonColor={"secondary"}
              buttonType={"solid"}
              buttonSize={"sm"}
              customStyle={"py-3 text-xl"}
              onClick={() => initializeChat()}
            >
              Sure let's go! !
            </CustomButton>
          </div>
        </>
      )}
      {initializeChatbot && (
        <div
          ref={chatRef}
          className="transition-opacity duration-1000 ease-in-out opacity-100 flex items-center justify-center h-full w-full relative"
        >
          <div
            className=" w-1/2 h-4/5 relative"
            id="welcome-chat-container"
          ></div>
        </div>
      )}
    </div>
  );
}

export default WelcomePage;
