/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { store } from "../../../reducers/store";
import { setAuthData } from "../../../reducers/auth.reducer";
import CustomButton from "../../../components/CustomButton";
import NewOffenderModel from "./NewOffenderModal";
import { baseUrl } from "../../../utils/constants";
import UsersIcon from "../../../sources/icons/UsersIcon.svg";
import LogoutIcon from "../../../sources/icons/LogoutIcon.svg";
import UserGroup from "../../../sources/icons/UserGroup.svg";
import AnalyticsIcon from "../../../sources/icons/AnalyticsIcon.svg";
import HomeIcon from "../../../sources/icons/HomeIcon.svg";
import { toast } from "react-toastify";

function OffenderPage() {
  const navigate = useNavigate();
  const [offenders, setOffenders] = useState([]);
  const [isOffendersFetched, setOffendersFetched] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reducer = store.getState().authReducer;

  useEffect(() => {
    if(!isOffendersFetched) {
      loadOffendersData();
      setOffendersFetched(true)
    }
    // eslint-disable-next-line
  }, []);

  const loadOffendersData = () => {
    const userToken = reducer.userToken;
    axios
      .get(`${baseUrl}/api/user/get-offenders`, {
        headers: {
          "Content-Type": "application/json",
          authorization: userToken,
        },
      })
      .then((res) => {
        if (!res.data.success) {
          navigate("/");
          toast(res.data.message, { type: "error" });
        } else {
          setOffenders(res.data.offenders);
        }
      })
      .catch((err) => {
        console.error(err);
        toast("something went wrong", { type: "error" });
      });
  };

  const handleCreateNewOffender = (event, setUserDetails, userDetails) => {
    event.preventDefault();
    const loadingID = toast.loading("Please wait...");
    const userToken = localStorage.getItem("user-token");
    axios
      .post(`${baseUrl}/api/user/add-offender`, userDetails, {
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(userToken),
        },
      })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.success) {
          toast.update(loadingID, {
            render: "Successfully added new offender",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          closeModal();
          setUserDetails({});
        } else {
          toast.update(loadingID, {
            render: "something went wrong. try again later.",
            type: "error",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.update(loadingID, {
          render: "something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          closeOnClick: true,
        });
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onLogOut = () => {
    localStorage.removeItem("user-token");
    store.dispatch(
      setAuthData({
        userId: null,
        userToken: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        userRole: null,
      })
    );
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="container">
      <div className="flex flex-row w-full h-full">
        <div className="basis-1/5 bg-gray-50">
          <div className=" border-r-2 border-gray-400 px-2 ml-4 my-5">
            <div className="flex flex-col justify-between h-screen pb-10">
              <div>
                <h1 className="font-semibold text-xl text-primary">
                  Kanka Bot Logo
                </h1>
                <ul className="mt-10">
                  <li className="flex flex-row gap-2 mx-1">
                    <img src={HomeIcon} height={20} width={20} alt="" />
                    <a
                      href="#"
                      className="mt-1 text-lg text-gray-400 focus:text-gray-800"
                    >
                      Home
                    </a>
                  </li>
                  <li className="flex flex-row gap-2 mx-1">
                    <img src={UsersIcon} height={20} width={20} alt="" />
                    <a
                      href="#"
                      className="mt-1 text-lg text-gray-400 focus:text-gray-800"
                    >
                      Supervisors
                    </a>
                  </li>
                  <li className="flex flex-row gap-2 mx-1">
                    <img src={UserGroup} height={20} width={20} alt="" />
                    <a
                      href="#"
                      className="mt-1 text-lg text-gray-400 focus:text-gray-800"
                    >
                      Offenders
                    </a>
                  </li>
                  <li className="mx-1">
                    <div className="flex flex-row gap-2">
                      <img src={AnalyticsIcon} height={20} width={20} alt="" />
                      <a
                        href="#"
                        className="mt-1 text-lg text-gray-400 focus:text-gray-800"
                      >
                        Analytics
                      </a>
                    </div>
                    <ul className="list-disc ml-11">
                      <li className="text-md text-gray-400 focus:text-gray-800">
                        Conversations
                      </li>
                      <li className="text-md text-gray-400 focus:text-gray-800">
                        Conditions
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <CustomButton
                  onClick={onLogOut}
                  buttonSize="full"
                  buttonType="outline"
                  buttonColor="danger"
                  customStyle=""
                >
                  <span className="flex flex-row gap-2 items-center justify-center">
                    <img src={LogoutIcon} height={20} width={20} alt="" />
                    Logout
                  </span>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-4/5 pl-10 my-5">
          <h1 className="text-2xl mt-20 font-bold text-left">Offenders</h1>

          <div className="max-w-md my-2">
            <CustomButton
              onClick={openModal}
              buttonSize="sm"
              buttonType="outline"
              buttonColor="primary"
              customStyle="text-md"
            >
              <span className="flex flex-row gap-2 items-center justify-center">
                Create New
              </span>
            </CustomButton>
          </div>

          <div className="mt-5 border rounded-lg">
            <table className="table-auto w-full">
              <thead className="text-gray-700 text-md border-b dark:border-neutral-500">
                <tr className>
                  <td className="p-4 text-right">S. No.</td>
                  <td className="p-4">First Name</td>
                  <td className="p-4">Last Name</td>
                  <td className="p-4">Email</td>
                </tr>
              </thead>
              <tbody className="text-gray-900 text-md">
                {offenders &&
                  offenders.map((offender, idx) => (
                    <tr
                      key={idx}
                      className="hover:text-primary border-b dark:border-neutral-500 hover:bg-neutral-100"
                    >
                      <td className="p-4 text-right">{idx + 1}</td>
                      <td className="p-4">{offender.firstName}</td>
                      <td className="p-4">{offender.lastName}</td>
                      <td className="p-4">{offender.email}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <NewOffenderModel
            isOpen={isModalOpen}
            closeModal={closeModal}
            handleCreateNewOffender={handleCreateNewOffender}
          />
        </div>
      </div>
    </div>
  );
}

export default OffenderPage;
