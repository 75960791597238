import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import ChevronDownIcon from "../../../sources/icons/ChevronDown.svg";
import PlusIcon from "../../../sources/icons/PlusIcon.svg";
import axios from "axios";
import { baseUrl } from "../../../utils/constants";
import { store } from "../../../reducers/store";
import { toast } from "react-toastify";

const QuickConversationButton = ({ openModal }) => {
  const reducer = store.getState().authReducer;
  const [dropDownItems, setDropDownItems] = useState([]);
  const [isDropdownItemsFetched, setDropdownItemsFetched] = useState(false)
  
  const fetchDropDownItems = () => {
    const userToken = reducer.userToken;
    axios
      .get(`${baseUrl}/api/conversation/list-treatment-tools`, {
        headers: {
          "Content-Type": "application/json",
          authorization: userToken,
        },
      })
      .then((res) => {
        if (!res.data.success) {
          toast(res.data.message, { type: "error" });
        } else {
          setDropDownItems(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
        toast("Something went wrong", { type: "error" });
      });
  };

  const createQuickConversation = async (item) => {
    // try {
    //   console.log(item);
    //   let userToken = localStorage.getItem("user-token");
    //   userToken = JSON.parse(userToken);
    //   const response = await axios.post(
    //     baseUrl + "/api/conversation/create-conversation",
    //     {
    //       conversationTitle: item.name,
    //       conversationDescription: "Created by quick conversation tool.",
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: userToken,
    //       },
    //     }
    //   );

    //   if (response.status === 201) {
    //     // Trigger the callback to fetch conversations
    //     window.open("/?conversationId=" + response.data.bpConversationId+"&flow="+, "_self");
    //   } else {
    //     console.error("Failed to create conversation");
    //   }
    // } catch (error) {
    //   console.error("Error creating conversation:", error);
    // }
  };

  useEffect(() => {
    if (!isDropdownItemsFetched) {
      setDropdownItemsFetched(true)
      fetchDropDownItems();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div className="bg-[#6B7DBB] text-white flex flex-row items-center justify-between rounded-lg pl-2">
          <div
            className="flex flex-row items-center justify-center cursor-pointer mr-2"
            onClick={openModal}
          >
            <img
              src={PlusIcon}
              alt="Search Icon"
              height={20}
              width={20}
              onClick={() => {}}
              className="cursor-pointer stroke-white text-white"
            />
            <div className="mt-0.5 ml-1 text-sm">Create</div>
          </div>
          <Menu.Button className="flex py-2 px-2 justify-center rounded-r-lg border-l-1 text-sm font-medium hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            <img
              src={ChevronDownIcon}
              alt="Menu Icon"
              height={16}
              width={16}
              color="#ffff"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 overflow-y-scroll max-h-44 mt-2 w-56 origin-top-right divide-y divide-gray-200 px-2 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            {dropDownItems.map((item, itemIndex) => {
              return (
                <div className="py-1" key={itemIndex}>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          createQuickConversation(item);
                        }}
                        className={`${
                          active ? "bg-[#6B7DBB] text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default QuickConversationButton;