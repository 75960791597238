import React, { useState , useEffect} from 'react';
import axios from 'axios';
import { baseUrl } from "../../../utils/constants";
import CustomModal from '../../../components/CustomModal';
import CustomButton from "../../../components/CustomButton";



const NewConversationModal = ({ isOpen, closeModal, onCreateConversation }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    // Use a useEffect to reset the state when the modal is closed
    if (!isOpen) {
      setTitle('');
      setDescription('');
    }
  }, [isOpen]);

  
  const handleCreateConversation = async (e) => {
    e.preventDefault();
    
    if (!title || !description) {
      setError("Please fill in both title and description fields.");
      setTitle('');
      setDescription('')
      return;
    }

    try {
      let userToken = localStorage.getItem("user-token");
      userToken = JSON.parse(userToken);
      const response = await axios.post(baseUrl + "/api/conversation/create-conversation", {
        conversationTitle: title,
        conversationDescription: description,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: userToken,
        },
      });

      if (response.status === 201) {
        setError('');
        closeModal();  
        // Trigger the callback to fetch conversations
        if (typeof onCreateConversation === 'function') {
          onCreateConversation();
        }
      } else {
        console.error('Failed to create conversation');
        setError("Failed to create conversation. Please try again.");
      }
    } catch (error) {
      console.error('Error creating conversation:', error);
      setError("An error occurred while creating the conversation. Please try again.");
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={closeModal}
      title="Create a conversation"
      titleStyle={"w-full flex items-center justify-center mb-2"}
    >
      <form onSubmit={handleCreateConversation}>
        <input
          type="text"
          placeholder="Conversation Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full border rounded-md p-3 mb-4"
          style={{ fontSize: "14px" }}
        />
        <textarea
          placeholder="Conversation Description"
          value={description}
          maxLength={150}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full border rounded-md p-3 mb-4 h-32"
          style={{ fontSize: "14px" }}
          draggable="false"
        />
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-end flex-row">
          <CustomButton
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
            onClick={(e) => {
              e.preventDefault(); 
              closeModal();
            }}
            customStyle={"mr-2"}
          >
            Cancel
          </CustomButton>
          <CustomButton
            buttonColor={"primary"}
            buttonType={"solid"}
            buttonSize={"lg"}
          >
            Create conversation
          </CustomButton>
        </div>
      </form>
    </CustomModal>
  );
}

export default NewConversationModal;
