import HomePage from '../pages/home/HomePage';
import LoginPage from '../pages/login/LoginPage';
import ProtectedRoute from '../components/ProtectedRoute';
import RegisterPage from '../pages/register/RegisterPage';
import WelcomePage from '../pages/welcome/WelcomePage';

import OffenderPage from '../pages/admin/offender/OffenderPage';

export const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/welcome",
    element: (
      <ProtectedRoute>
        <WelcomePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/admin/offender",
    element: (
      <ProtectedRoute>
        <OffenderPage />
      </ProtectedRoute>
    )
  }
];