import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import VideoRecorder from "./VideoRecorder";
import Avatar from "../../sources/lotties/avatar.json";
import Lottie from "react-lottie";
import TypingText from "../../components/TypingText";
import BehaviorChainForm from "./BehaviorChainForm";
import CostBenefitForm from "./CostBenefitForm";
import ProblemSolving from "./ProblemSolvingForm";
import DisplayVideo from "./DisplayVideo";
import CustomButton from "../../components/CustomButton";
import SocialSkillForm from "./SocialSkillsForm";

const FlowProvider = forwardRef(function FlowProvider(
  {
    avatar: { showAvatar, avatarText, setShowAvatarStatus },
    displayBehaviorChainForm: {
      setShowBehaviorChainForm,
      behaviorChainFormText,
      showBehaviorChainForm,
    },
    displayCostBenefitForm: {
      setShowCostBenefitForm,
      costBenefitFormData,
      showCostBenefitForm,
    },
    displayProblemSolvingForm: {
      setShowProblemSolvingForm,
      problemSolvingFormData,
      showProblemSolvingForm,
    },
    displaySocialSkillForm: {
      setShowSocialSkillForm,
      socialSkillFormData,
      showSocialSkillForm,
    }
  },
  ref
) {
  const videoRecorderRef = useRef(null);
  const displayVideoRef = useRef(null);
  const typingTextRef = useRef(null);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);

  useImperativeHandle(ref, () => ({
    videoRecorder: {
      showVideoRecorder: ({ title, description, currentConversationId }) => {
        if (videoRecorderRef.current) {
          videoRecorderRef.current.showVideoRecorder({
            title,
            description,
            currentConversationId,
          });
        }
      },
      hideVideoRecorder: () => {
        if (videoRecorderRef.current) {
          videoRecorderRef.current.hideVideoRecorder();
        }
      },
    },
    displayVideo: {
      showVideoDisplayer: ({ currentConversationId }) => {
        if (displayVideoRef.current) {
          displayVideoRef.current.showVideoDisplayer({ currentConversationId });
        }
      },
      hideVideoDisplayer: () => {
        if (displayVideoRef.current) {
          displayVideoRef.current.hideVideoDisplayer();
        }
      },
    },
  }));

  return (
    <>
      <VideoRecorder ref={videoRecorderRef} />
      <DisplayVideo ref={displayVideoRef} />
      {showAvatar && (
        <div className="absolute flex-1 w-screen h-screen z-20 flex items-center justify-center">
          <div className="absolute z-50 flex opacity-100 flex-row">
            <Lottie
              className="z-50"
              options={{
                loop: true,
                autoplay: true,
                animationData: Avatar,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
            <div className="flex flex-col gap-4 items-end">
              <div className="bg-white -ml-36 w-72 rounded-md h-fit py-4 px-4">
                <TypingText
                  ref={typingTextRef}
                  text={avatarText}
                  delay={100}
                  onContinueStatusChange={() => {
                    setShowContinueButton(true);
                  }}
                  onCompleted={() => {
                    setShowCloseButton(true);
                  }}
                />
              </div>
              {showCloseButton && (
                <CustomButton
                  onClick={() => {
                    setTimeout(() => {
                      window.botpressWebChat.sendEvent({
                        type: "proactive-trigger",
                        channel: "web",
                        payload: { text: "Overview completed!" },
                      });
                      setShowAvatarStatus(false);
                      setShowCloseButton(false);
                    }, 1500);
                  }}
                  buttonSize="full"
                  buttonType="outline"
                  buttonColor="danger"
                  customStyle=""
                >
                  close
                </CustomButton>
              )}
              {showContinueButton && (
                <CustomButton
                  onClick={() => {
                    typingTextRef.current?.continueRenderingLetters();
                    setShowContinueButton(false);
                  }}
                  buttonSize="full"
                  buttonType="solid"
                  buttonColor="primary"
                  customStyle=""
                >
                  Continue
                </CustomButton>
              )}
            </div>
          </div>
          <div className="w-screen h-screen z-30 bg-zinc-800 opacity-80"></div>
        </div>
      )}
      {showBehaviorChainForm && (
        <BehaviorChainForm
          imageText={behaviorChainFormText}
          setShowImage={setShowBehaviorChainForm}
          closeModal={() => setShowBehaviorChainForm(false)}
        />
      )}
      {showCostBenefitForm && (
        <CostBenefitForm
          costBenefitFormData={costBenefitFormData}
          setShowCostBenefitForm={setShowCostBenefitForm}
          closeModal={() => setShowCostBenefitForm(false)}
        />
      )}
      {showProblemSolvingForm && (
        <ProblemSolving
          problemSolvingFormData={problemSolvingFormData}
          setShowProblemSolvingForm={setShowProblemSolvingForm}
          closeModal={() => setShowProblemSolvingForm(false)}
        />
      )}
      {showSocialSkillForm && (
        <SocialSkillForm
          socialSkillFormData={socialSkillFormData}
          setShowSocialSkillForm={setShowSocialSkillForm}
          closeModal={() => setShowSocialSkillForm(false)}
        />
      )}
    </>
  );
});
export default FlowProvider;
