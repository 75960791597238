import axios from "axios";
import { store } from "../reducers/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "./constants";

export async function saveToolForm(file, formName) {
  const appReducer = store.getState().appReducer;
  const authReducer = store.getState().authReducer;

  try {
    const formData = new FormData();
    formData.append("image", file, formName + ".png");
    formData.append("formName", formName);

    const conversationId = appReducer.currentConversation?.conversationId;
    formData.append("conversationId", conversationId);

    const userToken = authReducer.userToken;

    await axios.post(baseUrl + "/api/conversation/save-tool-form", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: userToken,
      },
    });

  } catch (error) {
    toast.error("Error saving tool form...");
    console.error("Error uploading file", error);
  }
}
