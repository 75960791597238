import React, { forwardRef, useImperativeHandle, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { baseUrl } from "../../utils/constants";
import axios from "axios";
import { store } from "../../reducers/store";

const VideoPreview = ({ src }) => {
  return (
    <video
      width={500}
      height={500}
      autoPlay
      src={src}
      controls
      style={{ borderRadius: 20, borderWidth: 8 }}
    />
  );
};

//eslint-disable-next-line
const DisplayVideo = forwardRef(function VideoRecorder({}, ref) {
  const [showDisplayVideo, setDisplayVideoStatus] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const showVideoDisplayer = ({
    currentConversationId: currentConversation,
  }) => {
    pullVideoUrl(currentConversation);
    setDisplayVideoStatus(true);
  };

  const hideVideoDisplayer = () => {
    setDisplayVideoStatus(false);
  };

  useImperativeHandle(ref, () => ({
    showVideoDisplayer,
    hideVideoDisplayer,
  }));

  const pullVideoUrl = async (currentConversation) => {
    try {
      const userToken = store.getState().authReducer.userToken;

      const response = await axios.get(
        baseUrl +
          "/api/conversation/pull-last-conversation-recording/" +
          currentConversation,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: userToken,
          },
        }
      );

      setVideoUrl(response.data.data.url);
    } catch (error) {
      console.error("Error loading video:", error);
      if (error.response) {
        console.error(
          "Server responded with:",
          error.response.status,
          error.response.data
        );
      }
    }
  };

  const renderDisplayVideo = () => {
    if (showDisplayVideo) {
      return (
        <div className="absolute flex-1 w-screen h-screen z-20 flex items-center justify-center">
          <div className="absolute bg-white z-50 w-3/4 md:w-2/3 p-12 rounded-xl flex opacity-100 flex-row">
            <div className="w-full h-full items-center justify-center flex flex-col">
              {videoUrl && <VideoPreview src={videoUrl} />}
              <div className="w-full flex justify-center items-center mt-8">
                <CustomButton
                  buttonColor={"danger"}
                  buttonType={"solid"}
                  buttonSize={"lg"}
                  onClick={() => {
                    setTimeout(() => {
                      window.botpressWebChat.sendEvent({
                        type: "proactive-trigger",
                        channel: "web",
                        payload: { text: "Video displayed!" },
                      });
                    }, 500);
                    hideVideoDisplayer();
                  }}
                >
                  Close
                </CustomButton>
              </div>
            </div>
          </div>
          <div className="w-screen h-screen z-30 bg-zinc-800 opacity-80"></div>
        </div>
      );
    }
  };

  return <>{renderDisplayVideo()}</>;
});

export default DisplayVideo;
